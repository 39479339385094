<template>
  <div>
    <div class="table-button">
      <Button type="primary" @click="toAdd()">新增</Button>
    </div>
    <div class="table-input">
      <Input v-model="param.name" clearable placeholder="景点名称" />
      <Button type="primary" @click="search()">搜索</Button>
    </div>
    <Table :columns="columns" :data="data" @on-selection-change="changeSelection">

      <template slot-scope="{ row, index }" slot="minUrl">
        <img :src="item" style="width: 30px;height: 30px;margin:5px 5px 0 5px;cursor: zoom-in;" v-for="item,index in row.minUrlArr" :key="index" @click="MyUtil.showBigImg(item)" />
      </template>

      <template slot-scope="{ row, index }" slot="labelColor">
        <span :style="'color:'+row.labelColor">{{row.labelColor}}</span>
      </template>

      <template slot-scope="{ row, index }" slot="status">
        <span v-if="row.status==1" style="color:green">启用</span>
        <span v-if="row.status==0" style="color:red">禁用</span>
      </template>

      <template slot-scope="{ row, index }" slot="action">
        <Button v-if="row.status==1" type="primary" size="small" @click="toStatus(row)">禁用</Button>
        <Button v-if="row.status==0" type="primary" size="small" @click="toStatus(row)">启用</Button>
        <Button type="primary" size="small" @click="toOrder(row)">排序</Button>
        <Button type="primary" size="small" @click="toUpdate(row)">修改</Button>
        <Button type="primary" size="small" @click="toDelete(row)">删除</Button>
      </template>
    </Table>
    <Page :total="total" :current="param.page" :page-size="param.rows" show-total show-sizer @on-change="changePage" @on-page-size-change="changePageSize"></Page>

    <!-- 模态框 -->
    <Modal v-model="modalOrder" title="排序">
      <Form ref="formOrder" :model="formOrder" :rules="formOrderRules" :label-width="100">
        <FormItem label="排序：" prop="orderNum" style="width: 80%">
          <InputNumber v-model="formOrder.orderNum" :formatter="value => parseInt(value)"></InputNumber>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button @click="value=>this.modalOrder=false">关闭</Button>
        <Button type="primary" @click="saveOrderNum()">确定</Button>
      </div>
    </Modal>

    <!-- 模态框 -->
    <Modal v-model="modal" :title="modalTitle" width="600">
      <div style="color:red;margin-bottom:5px">*可随时保存已填信息，保存后为“禁用”状态，请在信息填完后，手动“启用”！</div>

      <Form ref="form" :model="form" :rules="formRules" :label-width="100">
        <FormItem label="景点名称：" prop="name" style="width: 80%">
          <Input v-model="form.name"></Input>
        </FormItem>
        <FormItem label="话题标签：" prop="topic" style="width: 80%">
          <Input v-model="form.topic" placeholder="示例：#4A级景区#公园"></Input>
        </FormItem>
        <FormItem label="景点描述：" prop="text" style="width: 80%">
          <Input v-model="form.text" type="textarea"></Input>
        </FormItem>
        <FormItem label="景点地址：" prop="address" style="width: 80%">
          <Input v-model="form.address"></Input>
        </FormItem>
        <FormItem label="经纬度：" prop="lntlat" style="width: 100%">
          <Row type="flex" justify="start">
            <Col style="width: 56%"><Input v-model="form.lntlat" placeholder="示例：103.82546,32.318312"></Input></Col>
            <Col> <a target="view_window" href="https://lbs.amap.com/tools/picker">&nbsp;&nbsp;获取经纬度
              <Icon type="ios-help-circle-outline" title="为避免地图版权纠纷，请前往官网查询经纬度" />
            </a>
            </Col>
          </Row>
        </FormItem>
        <FormItem label="标签颜色：" prop="labelColor" style="width: 80%">
          <ColorPicker v-model="form.labelColor" />
        </FormItem>
        <FormItem label="缩略头图：" prop="minUrl">
          <!-- 上传按钮 -->
          <Upload v-model="form.minUrl" :show-upload-list="false" :default-file-list="uploadList" :action="uploadUrl" :data="uploadData" :max-size="10240" :on-exceeded-size="()=> this.$Message.warning('文件大小不能超过10M')"
            :format="['jpg','jpeg','png']" :on-format-error="()=> this.$Message.warning('文件格式支持jpg,jpeg,png')" :before-upload="handleBeforeUpload" :on-progress="handleOnProgress" :on-success="handleOnSuccess">
            <Button :disabled="(uploadPercent>0&&uploadPercent<100) || uploadList.length>=1">上传缩略头图</Button><span style="font-size:12px;color:#999999">最多上传1张图片</span>
          </Upload>
          <!-- 上传预览 -->
          <div v-for="item,index in uploadList" style="display: inline-block;">
            <img style="width: 100px;height: 100px;margin:10px 0 0 0;cursor: zoom-in;" :src="item.url" @click="MyUtil.showBigImg(item.url)">
            <Icon type="ios-close" style="margin:10px 10px 0 -14px;cursor: pointer;vertical-align: top;background-color:#999999;color:#ffffff" @click="handleOnRemove(index)">
            </Icon>
          </div>
          <!-- 进度条 -->
          <Progress v-if="uploadPercent>0&&uploadPercent<100" :percent="uploadPercent" style="width: 100px;" :stroke-width="5"></Progress>
        </FormItem>

        <FormItem label="景点图片：" prop="maxUrl">
          <!-- 上传按钮 -->
          <Upload v-model="form.maxUrl" :show-upload-list="false" :default-file-list="uploadListA" :action="uploadUrl" :data="uploadData" :max-size="10240" :on-exceeded-size="()=> this.$Message.warning('文件大小不能超过10M')"
            :format="['jpg','jpeg','png']" :on-format-error="()=> this.$Message.warning('文件格式支持jpg,jpeg,png')" :before-upload="handleBeforeUpload" :on-progress="handleOnProgress" :on-success="handleOnSuccessA">
            <Button :disabled="(uploadPercent>0&&uploadPercent<100) || uploadListA.length>=9">上传景点图片</Button><span style="font-size:12px;color:#999999">最多上传9张图片</span>
          </Upload>
          <!-- 上传预览 -->
          <div v-for="item,index in uploadListA" style="display: inline-block;">
            <img style="width: 100px;height: 100px;margin:10px 0 0 0;cursor: zoom-in;" :src="item.url" @click="MyUtil.showBigImg(item.url)">
            <Icon type="ios-close" style="margin:10px 10px 0 -14px;cursor: pointer;vertical-align: top;background-color:#999999;color:#ffffff" @click="handleOnRemoveA(index)">
            </Icon>
          </div>
          <!-- 进度条 -->
          <Progress v-if="uploadPercent>0&&uploadPercent<100" :percent="uploadPercent" style="width: 100px;" :stroke-width="5"></Progress>
        </FormItem>

      </Form>
      <div slot="footer">
        <Button @click="value=>this.modal=false">关闭</Button>
        <Button type="primary" @click="save()">确定</Button>
      </div>
    </Modal>

  </div>
</template>

<script>
export default {
  data() {
    return {
      MyUtil: this.MyUtil,
      //列表
      deleteIds: [],
      param: {
        name: null,
        page: 1,
        rows: 10,
      },
      total: 0,
      data: [],
      columns: [
        {
          type: "selection",
          width: 35,
        },
        {
          title: "景点名称",
          key: "name",
          width: 150,
          ellipsis: true,
          tooltip: true,
        },
        {
          title: "景点地址",
          key: "address",
          width: 200,
          ellipsis: true,
          tooltip: true,
        },
        {
          title: "景点描述",
          key: "text",
          ellipsis: true,
          tooltip: true,
        },
        {
          title: "话题标签",
          key: "topic",
          width: 150,
          ellipsis: true,
          tooltip: true,
        },
        {
          title: "缩略头图",
          slot: "minUrl",
          width: 100,
        },
        {
          title: "标签颜色",
          slot: "labelColor",
          width: 100,
        },
        {
          title: "排序",
          key: "orderNum",
          width: 100,
        },
        {
          title: "状态",
          slot: "status",
          width: 100,
        },
        {
          title: "操作",
          slot: "action",
          fixed: "right",
          width: 240,
        },
      ],
      //排序
      modalOrder: false,
      formOrder: {
        orderNumId: null,
        orderNum: null,
      },
      formOrderRules: {
        orderNum: [
          {
            required: true,
            message: "此项不能为空",
          },
        ],
      },

      //模态框
      modal: false,
      modalTitle: "",
      options: [],
      optionsLoading: false,
      form: {
        name: null,
        topic: null,
        text: null,
        labelColor: null,
        address: null,
        lntlat: null,
        minUrl: null,
        maxUrl: null,
      },
      formRules: {
        name: [
          {
            required: true,
            message: "此项不能为空",
          },
        ],
        // text: [
        //   {
        //     required: true,
        //     message: "此项不能为空",
        //   },
        // ],
        // labelColor: [
        //   {
        //     required: true,
        //     message: "此项不能为空",
        //   },
        // ],
        // address: [
        //   {
        //     required: true,
        //     message: "此项不能为空",
        //   },
        // ],
        // lntlat: [
        //   {
        //     required: true,
        //     message: "此项不能为空",
        //   },
        // ],
        // minUrl: [
        //   {
        //     required: true,
        //     message: "此项不能为空",
        //     trigger: "change",
        //   },
        // ],
        // maxUrl: [
        //   {
        //     required: true,
        //     message: "此项不能为空",
        //     trigger: "change",
        //   },
        // ],
      },
      //上传
      uploadPercent: 0, //上传百分比
      uploadUrl: "", //上传地址
      uploadDir: "scenicspoturl", //存储位置
      uploadData: {}, //上传参数
      uploadList: [], //已上传文件列表
      uploadListA: [], //已上传文件列表
    };
  },
  methods: {
    getDataList() {
      this.MyUtil.ajax()
        .post("/ScenicSpotController/getScenicSpotList", this.param)
        .then((resData) => {
          this.data = resData.data;
          this.total = resData.total;
          if (this.data) {
            this.data.forEach((element) => {
              if (element.minUrl) {
                element.minUrlArr = element.minUrl.split(";");
              }
            });
          }
        });
    },
    changePage(page) {
      this.param.page = page;
      this.getDataList();
    },
    changePageSize(rows) {
      this.param.rows = rows;
      this.getDataList();
    },
    changeSelection(selection) {
      this.deleteIds = [];
      selection.forEach((element) => {
        this.deleteIds.push(element.id);
      });
    },
    search() {
      this.param.page = 1;
      this.getDataList();
    },


    toAdd(row) {
      this.$router.push({
        name: "scenicSpotAdd",
      });
    },

    toUpdate(row) {
      this.$router.push({
        name: "scenicSpotUpdate",
        params: {
          row: row,
        },
      });
    },




    // toAdd() {
    //   this.$refs.form.resetFields();
    //   this.$nextTick(() => {
    //     this.modalTitle = "新增";
    //     this.uploadList = [];
    //     this.uploadListA = [];
    //     this.form.id = null;
    //     this.modal = true;
    //   });
    // },

    // toUpdate(row) {
    //   this.$refs.form.resetFields();
    //   this.$nextTick(() => {
    //     this.modalTitle = "修改";
    //     this.uploadList = [];
    //     this.uploadListA = [];

    //     this.form.id = row.id;
    //     this.form.minUrl = row.minUrl;
    //     this.form.maxUrl = row.maxUrl;
    //     this.form.name = row.name;
    //     this.form.topic = row.topic;
    //     this.form.text = row.text;
    //     this.form.labelColor = row.labelColor;
    //     this.form.address = row.address;
    //     if (row.longitude > 0 || row.latitude > 0) {
    //       this.form.lntlat = row.longitude + "," + row.latitude;
    //     }

    //     //加载已上传图片
    //     if (row.minUrl) {
    //       let urlArr = row.minUrl.split(";");
    //       urlArr.forEach((element) => {
    //         this.uploadList.push({
    //           name: "",
    //           url: element,
    //         });
    //       });
    //     }

    //     if (row.maxUrl) {
    //       let urlArr = row.maxUrl.split(";");
    //       urlArr.forEach((element) => {
    //         this.uploadListA.push({
    //           name: "",
    //           url: element,
    //         });
    //       });
    //     }

    //     this.modal = true;
    //   });
    // },

    toOrder(row) {
      this.formOrder.orderNumId = row.id;
      this.formOrder.orderNum = row.orderNum;
      this.modalOrder = true;
    },

    toStatus(row) {
      this.$Modal.confirm({
        title: "操作确认",
        content: "点击确认，以继续执行该操作！",
        onOk: () => {
          this.MyUtil.ajax()
            .post("/ScenicSpotController/updateScenicSpotStatus", {
              id: row.id,
            })
            .then((resData) => {
              this.$Message.success("操作成功");
              this.getDataList();
            });
        },
      });
    },

    toDelete(row) {
      let ids = [];
      if (row) {
        ids.push(row.id);
      } else {
        if (this.deleteIds.length < 1) {
          this.$Message.warning("请至少选择一条数据");
          return;
        }
        ids = this.deleteIds;
      }
      this.$Modal.confirm({
        title: "操作确认",
        content: "点击确认，以继续执行该操作！",
        onOk: () => {
          this.MyUtil.ajax()
            .post("/ScenicSpotController/deleteScenicSpot", {
              deleteIds: ids,
            })
            .then((resData) => {
              this.$Message.success("删除成功");
              this.getDataList();
              this.deleteIds = [];
            });
        },
      });
    },

    handleBeforeUpload(file) {
      let fileName = file.name;
      let fileSuffix = fileName.substring(
        fileName.lastIndexOf("."),
        fileName.length
      );
      //获取oss文件上传授权
      return new Promise((resolve, reject) => {
        this.MyUtil.ajax()
          .post("/FileController/getOssAuthorize", {
            dir: this.uploadDir,
          })
          .then((resData) => {
            //设置上传参数
            this.uploadData = resData;
            //设置上传地址
            this.uploadUrl = resData.host;
            //设置存储路径
            this.uploadData.key =
              this.uploadDir + "/" + this.MyUtil.uuid() + fileSuffix;
            resolve(true);
          });
      });
    },
    handleOnProgress(event) {
      //设置上传进度
      if (event.percent) {
        this.uploadPercent = event.percent;
      }
    },
    handleOnSuccess(response, file) {
      //设置已上传文件
      this.uploadList.push({
        name: file.name,
        url: this.MyUtil.fileUrl + this.uploadData.key,
      });
      let urlArr = [];
      this.uploadList.forEach((element) => {
        urlArr.push(element.url);
      });
      this.form.minUrl = urlArr.join(";");
    },

    handleOnRemove(index) {
      this.uploadList.splice(index, 1);
      let urlArr = [];
      this.uploadList.forEach((element) => {
        urlArr.push(element.url);
      });
      this.form.minUrl = urlArr.join(";");
    },

    handleOnSuccessA(response, file) {
      //设置已上传文件
      this.uploadListA.push({
        name: file.name,
        url: this.MyUtil.fileUrl + this.uploadData.key,
      });
      let urlArr = [];
      this.uploadListA.forEach((element) => {
        urlArr.push(element.url);
      });
      this.form.maxUrl = urlArr.join(";");
    },

    handleOnRemoveA(index) {
      this.uploadListA.splice(index, 1);
      let urlArr = [];
      this.uploadListA.forEach((element) => {
        urlArr.push(element.url);
      });
      this.form.maxUrl = urlArr.join(";");
    },

    saveOrderNum() {
      this.$refs.formOrder.validate((valid) => {
        if (valid) {
          this.MyUtil.ajax()
            .post(
              "/ScenicSpotController/updateScenicSpotOrderNum",
              this.formOrder
            )
            .then((resData) => {
              this.$Message.success("保存成功");
              this.getDataList();
              this.modalOrder = false;
            });
        }
      });
    },

    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.lntlat) {
            let arr = this.form.lntlat.split(",");
            this.form.longitude = arr[0]; // 经度
            this.form.latitude = arr[1]; // 纬度
            if (!(this.form.longitude > 0 && this.form.latitude > 0)) {
              this.$Message.warning("经纬度格式错误！");
              return;
            }
          }
          this.MyUtil.ajax()
            .post("/ScenicSpotController/addScenicSpot", this.form)
            .then((resData) => {
              this.$Message.success("保存成功");
              this.getDataList();
              this.modal = false;
            });
        }
      });
    },
  },
  created() {
    this.getDataList();
  },
};
</script>
